.showroom-checklist{

    .button-wrap{
        display: flex;
        justify-content: flex-end;

        .ant-btn-link{
            font-size: 12px;
        }
    }

    .check-results{
        font-size: 12px;
        margin-bottom: 3px;
    }

    .check-step-results{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        width: 100%;
        flex: 1;

        li{
            background-color: var(--default-button-color-hex);
            min-height: 10px;
            flex: 1;
            margin-left: 5px;
            border-radius: 5px;

            &:first-child{
                margin-left: 0;
            }

            &.done{
                background-color: var(--success-color);
            }
        }
    }

    .ant-btn-link{
        font-weight: bold;
    }

    &-drawer{

        h2{
            margin-bottom: 24px;
        }

        .status{
            margin-bottom: 20px;
        }

        .ant-drawer{
            &-body{
                padding: 90px 0 40px;

                .wrap{
                    padding: 0  25px 0 40px;
                }
            }
        }

        .showroom-checklist{
            margin-bottom: 40px;
        }

        .ant-collapse{

            h5{
                font-size: 12px;
                text-transform: uppercase;
            }

            h6{
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 6px;
                line-height: 1em;
            }

            &-item{
                padding: 0 !important;
                border-top: 1px solid #C3C3C3 !important;

                &:last-child{
                    border-bottom: 1px solid #C3C3C3 !important;
                }

                &-active{
                    .ant-collapse-header{
                        .anticon{
                            transform: rotate(-180deg) !important;
                        }

                    }
                }
            }

            &-header{
                padding: 12px 60px 12px 40px !important;

                .sub-text{
                    font-size: 12px;

                    &-success{
                        @extend .sub-text;
                    }
                }

                .anticon{
                    position: absolute;
                    top: 15px !important;
                    right: 20px !important;
                    transform: initial !important;
                    font-size: 30px !important;
                    color: var(--sub-text-color) !important;
                    transition: all 0.25s ease-in-out
                }
            }

            &-content-box{
                padding: 0 !important;
                line-height: 1.2em;

                .link-button{
                    height: 34px;
                    font-size: 12px;
                    margin-top: 8px;
                    display: inline-flex;
                }

                .step{
                    padding: 8px 25px 15px 40px !important;
                    display: flex;

                    .success-badge,
                    .description:after{
                        display: none;
                    }

                    &.done{
                        .link-button, .sub-text{
                            display: none;
                        }

                        .success-badge{
                            width: 34px;
                            height: 34px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 28px;
                            overflow: hidden;
                            border-radius: 50%;
                            background-color: var(--success-color);
                            color: var(--base-color);
                            margin-right: 10px;
                        }

                        .description:after{
                            display: block;
                            content: "Done";
                            font-size: 12px;
                            color: var(--success-color);
                        }
                    }

                    & + .step{
                        padding-top: 15px !important;
                        border-top: 1px solid var(--default-shapes-color);
                    }
                }
            }
        }
    }
}

.menu-popover{
    .showroom-checklist{
        margin: 16px 0 8px;
    }
}