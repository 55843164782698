.showroom-attachment-upload{
    background-color: var(--default-shapes-color);
    border: 1px dashed var(--sub-text-color);
    color: var(--sub-text-color);
    height: 90px;
    width: 100%;
    cursor: pointer;
    padding: 0 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:hover{
        border-color: var(--primary-color-hex);
    }

    .item-loader{
        margin-left: 12px;

        .anticon{
            font-size: 34px !important;
        }
    }

    .anticon{
        font-size: 68px;
        margin-right: 10px;
    }

    &-wrap{
        display: block;
        width: 100%;
        margin-bottom: 30px;

        .ant-upload{
            display: block !important;
        }
    }

}