.showroom-downloads{

    --element-color: #C3C3C3;

    .attachment{

        &-row{
            display: flex;

            padding: 15px 0;
            border-top: 1px solid var(--element-color);

            &:last-child{
                border-bottom: 1px solid var(--element-color);
            }
        }



        &-description{
            padding: 0 10px;
            flex: 1;
        }

        &-action{
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .link-button{
                .anticon{
                    margin-right: 4px;
                    font-size: 30px;
                }
            }

            button{
                background-color: transparent;
                padding: 0 3px;
                min-width: auto;
                height: 40px;
                width: 40px;
                border-color: transparent !important;
                color: var(--sub-text-color);
                box-shadow: none !important;

                &:hover{
                    color: var(--primary-color-hex);
                }

                & + button{
                    margin-left: 10px;
                }

                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .anticon{
                    font-size: 33px;
                }

                .anticon-spin{
                    font-size: 20px !important;
                }
            }
        }
    }

    .empty{
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.showroom-downloads,
.stand-download-edit-modal{

    --element-color: #C3C3C3;

    .attachment {

        &-file{
            display: flex;
            flex: 1;


            .icon-wrap{
                margin-right: 15px;
                font-size: 48px;

                .anticon{
                    color: var(--element-color);
                }
            }

            .file-wrap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height: 1.3em;

                .name{

                    &.draft{
                        span{
                            color: var(--error-color);
                        }
                    }

                    &:not(.draft){
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.stand-download-edit-modal{

    .ant-modal-close{
        right: 10px;
        left: auto;
        top: 10px;

        .anticon{
            margin: 0;
        }
    }

    .ant-modal-body{
        padding-top: 50px !important;
    }

    h2{
        margin-bottom: 0;
    }

    .attachment-description{
        label{
            height: 1em;
            display: block;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.2em;
            color: var(--sub-text-color);
            margin-bottom: 5px;
        }
    }

    .ant-input-textarea-show-count{
        position: relative;

        .ant-input{
            height: 90px !important;
        }

        &:after {
            bottom: initial;
            top: -20px;
            font-weight: bold;
            font-size: 12px;
            right: 0;
            position: absolute;
        }
    }

    .icon-wrap{
        font-size: 68px !important;
        margin-right: 10px !important;
    }

    .ant-modal-footer{
        flex-direction: row !important;
    }
}

