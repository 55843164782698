$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';

:root {
    --base-color: #{hexToStr($base-color)};
    --base-color-rgb: #{hexToRGB($base-color)};

    --primary-color: #{hexToRGB($primary-color)};
    --primary-color-hex: #{hexToStr($primary-color)};

    --primary-bg-color-hex: #{hexToStr($primary-bg-color)};
    --sub-bg-color-hex: #{hexToStr($sub-bg-color)};

    --text-color: #{hexToStr($primary-text-color)};
    --text-color-rgb: #{hexToRGB($primary-text-color)};
    --text-on-bg-color: #{hexToStr($base-color)};

    --sub-text-color: #{hexToStr($primary-sub-text-color)};
    --second-sub-text-color: #{hexToStr($second-sub-text-color)};

    --default-button-color: #{hexToRGB($default-button-color)};
    --default-button-color-hex: #{hexToStr($default-button-color)};

    --default-badge-color: #{hexToStr($default-badge-color)};

    --default-shapes-color: #{hexToStr($default-shapes-color)};

    --error-color:  #{hexToStr($error-color)};
    --success-color:  #{hexToStr($success-color)};
    --warning-color:  #{hexToStr($warning-color)};

    --analytics-showroom-views-color:  #{hexToStr($analytics-showroom-views-color)};
    --analytics-showroom-favorites-color:  #{hexToStr($analytics-showroom-favorites-color)};
    --analytics-product-views-color:  #{hexToStr($analytics-product-views-color)};
    --analytics-product-favorites-color:  #{hexToStr($analytics-product-favorites-color)};
    --analytics-document-views-color:  #{hexToStr($analytics-document-views-color)};
    --analytics-video-views-color:  #{hexToStr($analytics-video-views-color)};

    --card-glow-color: #{hexToRGB($primary-text-color)};

    --antd-wave-shadow-color: var(--primary-color-hex);

    --main-offset: 0;
    --app-offset: 0%;
}

body,
p{
    margin: 0;
}

html, body{
    height: auto;
    min-height: 100%;
}

body{
    background-color: var(--base-color);
    font-size: $primary-font-size;
    font-family: $primary-font;
    line-height: $primary-line-height;
    color: var(--text-color);
    //overflow-y: scroll;
}

h1{
    font-size: 28px;
    line-height: 1.3em;
    text-transform: uppercase;
}

h2{
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.2em;
}

h3{
    font-size: 22px;
    font-weight: bold;
    line-height: 1.2em;
    margin: 0;
}

h4{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.3em;
}

h5{
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2em;
    margin: 0;
}

.sub-text{
    color: var(--sub-text-color);
    font-size: 14px;

    &-success{
        color: var(--success-color) !important;
        @extend .sub-text;
    }

    &-bold{
        font-weight: bold;
        @extend .sub-text;

        &-success{
            @extend .sub-text-success;
            @extend .sub-text-bold;
        }
    }
}



.color{
    &-text{
        color: var(--text-color);
    }

    &-on-bg-text{
        color: var(--text-on-bg-color);
    }

    &-primary{
        color: var(--primary-color-hex);
    }

    &-error{
        color: var(--error-color);
    }

    &-success{
        color: var(--success-color);
    }

    &-warning{
        color: var(--warning-color);
    }
}

.bg-none{
    background: none;
}

.bg-base{
    background: var(--base-color);
}


.bg-primary{
    background: var(--primary-color-hex);

    &-gradient{
        @include bg-radial-gradient;
    }

    &-image{
        background: white var(--main-bg) no-repeat scroll center center;
        background-size: cover;
    }

    &-image.main-menu{
        background-image: var(--header-bg);
        background-position: top;
    }

    &-bg-color{
        background: var(--primary-bg-color-hex);
    }
}

header.bg-primary-image{

}

.ant-form{
    [role="alert"]{
        color: var(--error-color);
        font-size: 12px;
        padding: 0 10px;
    }

    .field-group &-item{
        margin-bottom: 0;
    }

    .field-group,
    & *:not(.field-group) &-item{

        margin-bottom: 24px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    .field-group{
        display: flex;

        & > *{
            display: flex;
            flex-direction: column;
            flex: 1;

            .ant-col-24{
                flex: initial;
            }
        }

        & > * + *{
            margin-left: 22px;
        }
    }

    .ant-form-item-has-error{
        .ant-input-password,
        .ant-input{
            border-color: var(--error-color) !important;
        }

        .ant-checkbox{
            .ant-checkbox-inner{
                border-color: var(--error-color) !important;
            }
        }
    }
    .ant-form-item{
        .ant-input-textarea-show-count:after {
            bottom: initial;
            top: -20px;
            font-weight: bold;
            font-size: 12px;
            font-family: $primary-font;
            right: 0;
        }
    }

    .ant-radio-group-outline{
        display: flex;
    }

    .ant-radio-wrapper{
        display: flex;
        align-items: center;

        & + .ant-radio-wrapper{
            margin-left: 100px;
        }

        .ant-radio{

            &-input{
                &:focus + .ant-radio-inner{
                    box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.1) !important;
                }
            }

            &:hover{
                .ant-radio-inner{
                    border-color: var(--primary-color-hex);
                }
            }

            &.ant-radio-checked{
                &:after{
                    border-color: var(--primary-color-hex);
                }

                .ant-radio-inner{
                    border-width: 6px;
                    border-color: var(--primary-color-hex);
                }
            }

            &-inner{
                height: 21px;
                width: 21px;
                border-color: var(--second-sub-text-color);

                &:after{
                    display: none;
                }
            }
        }
    }

    .ant-col-24{
        &.ant-form-item-label{
            line-height: 1em;
            padding-bottom: 6px;

            .ant-form-item-required{
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;

                &:before{
                    color: var(--error-color) !important;
                }
            }

            > label{
                font-family: $primary-font;
                height: 1em;
                display: block;
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                line-height: 1.2em;
                color: var(--sub-text-color);
            }

        }
    }
}

.auth-loader{
    > * {
        min-height: 100vh;
    }
}

.ant-select-item-option-state{
    .anticon-check{
        color: var(--primary-color-hex) !important;
    }
}

.form-wrap{
    margin-top: 50px;

    h4{
        color: var(--primary-color-hex);
        border-bottom: 1px solid var(--primary-color-hex);
        padding-bottom: 10px;
        margin-bottom: 40px;
    }

    .ant-form{

        .field-group,
        & *:not(.field-group) &-item{

            margin-bottom: 32px;

            .ant-form-item-explain{
                position: absolute;
            }
        }

    }


    .ant-form-item-label{

        & > label{
            font-size: 12px !important;
            font-weight: bold;
        }
    }
}


.ant-input{

    &textarea{
        padding: 6px 10px;
    }

    &-affix-wrapper,
    :not(#{&}-affix-wrapper) > & {

        border-color: var(--second-sub-text-color);
        height: 41px;
        border-radius: 3px;

        &:hover {
            border-color: var(--primary-color-hex);
        }

        &:focus,
        &-focused {
            box-shadow: 0 0 0 2px rgba(var(--primary-color), 0.2);
            @extend .ant-input:hover;
        }
    }

    &-disabled{
        color: var(--text-color) !important;

        &:hover {
            border-color: var(--second-sub-text-color) !important;
        }
    }
}

.ant-checkbox{
    margin: 0 5px 0 0;

    input[type='checkbox']{
        width: 20px;
        height: 20px;
    }

    &-inner{
        top: 3px;
        width: 20px;
        height: 20px;

        &:after{
            top: 48%;
            width: 6.7px;
            height: 12px;
        }
    }

    &-checked{

        .ant-checkbox-inner{
            background-color: var(--primary-color-hex);
            border-color:  var(--primary-color-hex);
        }

        &:after{
            top: 3px;
            border-color:  var(--primary-color-hex);
        }
    }
    &-wrapper:hover,
    &:hover{
        .ant-checkbox-inner{
            border-color:  var(--primary-color-hex);
        }
    }

    &-input{
        &:focus{
            & + .ant-checkbox-inner{
                border-color:  var(--primary-color-hex);
            }
        }
    }
}

.ant-btn{
    height: 40px;
    border-radius: 20px;
    min-width: 130px;
    padding: 0 30px;
    text-transform: uppercase;
    font-size: 14px;

    span{
        position: relative;
        z-index: 2;
    }

    &-link{
        color: var(--primary-color-hex);
        text-transform: initial;
        height: auto;
        border-radius: initial;
        min-width: auto;
        padding: initial;

        &:hover{
            color: var(--text-color) !important;
        }
    }

    &-default{
        background-color: var(--default-button-color-hex) !important;
        border-color: var(--default-button-color-hex) !important;
        color: var(--text-color) !important;
        display: flex;
        align-items: center;

        &:before{
            @include button-overlay(rgba(255, 255, 255, 0.2));
            @extend %transition;
        }

        &:active {
            @extend .ant-btn-default;
            position: relative;

            &:before {
                @include button-overlay() ;
                opacity: 1;
            }
        }

        &:focus:not(:hover){
            @extend .ant-btn-default;
        }

        &:hover:not(:active){
            @extend .ant-btn-default;

            &:before{
                opacity: 1 !important;
                @include button-overlay(rgba(255, 255, 255, 0.2));
            }
        }

        &[disabled]:hover,
        &[disabled]{
            color: var(--text-color);
            background-color: rgba(var(--default-button-color), 0.5) !important;
            border-color: rgba(var(--default-button-color), 0.1) !important;
        }
    }

    &-background-ghost{
        color: var(--base-color);
        border: none;

        &:before{
            @include button-overlay(rgba(0, 0, 0, 0.6));
            opacity: 1 !important;
            @extend %transition;
        }

        &:hover{
            color: var(--base-color);

            &:before{
                @include button-overlay(rgba(0, 0, 0, 0.8));
            }
        }

        &:active{
            color: var(--base-color);

            &:before{
                @include button-overlay(rgba(0, 0, 0, 0.5));
            }
        }

        &:focus:not(:hover){
            @extend .ant-btn-background-ghost;
        }

    }

    &-primary{
        background-color: var(--primary-color-hex);
        border-color: var(--primary-color-hex);
        color: var(--base-color);

        &:before{
            @include button-overlay();
        }

        &:focus{
            @extend .ant-btn-primary;
        }

        &:active {
            @extend .ant-btn-primary;
            position: relative;

            &:before {
                opacity: 1;
            }
        }

        &:hover:not(:active){
            background-color: rgba(var(--primary-color), 0.8);
            border-color: rgba(var(--primary-color), 0.8);
        }

        &[disabled]:hover,
        &[disabled]{
            color: var(--base-color);
            background-color: rgba(var(--primary-color), 0.5) !important;
            border-color: rgba(var(--primary-color), 0.1) !important;
        }
    }
}

.link-button{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: var(--base-color) !important;
    text-decoration: none !important;
    height: 40px;

    @extend .ant-btn;
    @extend .ant-btn-primary;
    @extend %transition;

    &.second{
        @extend .ant-btn-default;
    }

}


.ant-select{

    min-width: 160px;

    & > &-selector{
        height: auto !important;
        min-height: 40px;
        padding: 0 16px !important;
        outline: none;
    }

    &-multiple > &-selector{
        padding: 0 16px 0 10px !important;
    }

    &-multiple &-selection-search{
        margin-inline-start: 0 !important;
    }

    &-selection-item{
        display: flex;
        align-items: center;
    }

    &:focus{
        outline: none !important;
    }

    &:not(#{&}-disabled).ant-select-focused,
    &:not(#{&}-disabled):hover{
        .ant-select-selector {
            border-color: rgba(var(--primary-color), 0.8) !important;
        }
    }

    &:not(#{&}-disabled).ant-select-focused{
        .ant-select-selector {
            box-shadow: 0 0 0 2px rgba(var(--primary-color), 0.2) !important;
        }
    }

    &-item-option-selected:not(#{&}-item-option-disabled){
        background-color: rgba(var(--primary-color), 0.2);
    }

    &-multiple{
        .ant-select-selection-item{
            background-color: var(--default-shapes-color);
            height: 30px;
            border-radius: 15px;
            padding-left: 10px;
            padding-right: 10px;

            &-content{
                margin-right: 6px;
            }
        }
    }

}

.ant-drawer{
    z-index: 1040;

    &-content-wrapper{
        width: 450px !important;
    }

    &-close{
        font-size: 30px;
    }
}

a{
    color: var(--text-color);

    &:hover{
        color: var(--sub-text-color);
    }
}

.dot-list{
    margin-top: 0;
    padding: 0;
    list-style: none;

    li{
        position: relative;
        padding-left: 25px;

        &:before{
            @extend %list-dot;
            left: 0;
            top: 8px;
        }
    }
}

.block-loader{

    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin{
        color: inherit;
    }

    .anticon-loading{
        font-size: 32px;
    }
}

.image-loader{
    position: relative;
    min-height: 200px;

    .item-loader{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;

    }

    &.loading{
        img{
            opacity: 0;
        }
    }

    img{
        @extend %transition;
        opacity: 1;
    }
}


.item-loader{

    .spin-bg {
        position: relative;

        &:before {
            content: "";
            border: 2px solid var(--sub-text-color);
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: block;
            position: absolute;
            opacity: 0.7;
        }
    }

    .ant-spin{
        color: inherit;
    }

    .anticon-loading{
        font-size: 24px;
    }
}

.badge{
    &-plus{
        background-color: var(--default-badge-color);
        width: 42px;
        height: 42px;
        display: flex;
        line-height: 1em;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 32px;
    }

    &-page-label{
        min-width: 150px;
        font-weight: bold;
        padding: 12px 40px;
        background: rgba(0, 0, 0, 0.4);
        color: var(--text-on-bg-color);
        border-radius: 26px;
        position: absolute;
        top: 75px;
        left: 50%;
        transform: translate(-50%);
        z-index: 10;
    }
}

.menu-popover{
    position: fixed;

    .ant-avatar{
        font-size: 59px !important;
    }

    .ant-popover {
        &-inner{
            border-radius: 4px;
        }

        &-title{
            display: flex;
            justify-content: space-between;
            border-bottom: none;
            align-items: center;
            padding: 6px 6px 6px 24px;

            h6{
                margin: 0;
                font-size: 12px;
                color: var(--sub-text-color);
            }
        }

        &-content {
            min-width: 300px;
        }



        &-inner-content{
            padding: 8px 24px 15px;
        }

    }

    .popover-menu{
        margin: 8px -10px 0;


        ul{
            @extend %list-reset;

            a,
            button{
                padding: 10px 14px 10px;
                color: var(--text-color);
                display: block;
                align-items: center;
                line-height: 1em;
                border-radius: 16px;

                @extend %transition;

                &:hover{
                    background-color: var(--sub-bg-color-hex);
                }

                &.with-icon{
                    padding: 3px 14px;
                    display: flex;

                    .anticon{
                        margin-right: 10px;
                        font-size: 30px;
                    }

                    &.but{
                        background-color: var(--default-button-color-hex);
                        border-color: var(--default-button-color-hex);

                        &:hover{
                            background-color: var(--sub-bg-color-hex);
                        }
                    }
                }
            }

            li + li{
                margin-top: 5px;
            }

            button{
                width: 100%;
                text-align: left;
                @extend %button-reset;
            }


        }
    }

    &-close,
    &-button{
        @extend %button-reset;
    }

    &-button{
        .ant-avatar{
            border: 2px solid var(--base-color) !important;
            @extend %transition;
        }

    }

    &-close{
        font-size: 24px;
        color: var(--sub-text-color);
        height: 24px;
        display: flex;

        @extend %transition;

        &:hover{
            color: var(--text-color);
        }
    }
}

.expand-button{
    width: 36px;
    height: 36px;
    position: absolute;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: var(--sub-text-color);
    color: var(--base-color);
}

.ant-modal{
    &-close{
        left: 20px;
        top: 20px;
        right: auto;

        &-x{
            display: flex;
            align-items: center;
            width: auto;
            height: 36px;
            font-size: 14px;
            text-transform: uppercase;
        }

        .anticon{
            margin-right: 8px;
            font-size: 36px;
        }
    }

    &.delete-confirm-modal{

        .ant-modal-close{
            left: 10px;
            top: 10px;
        }

        .ant-modal-content{
            padding: 50px 50px 40px;
        }

        .ant-modal-body{
            font-size: 16px !important;
            color: var(--text-color) !important;
            padding: 20px 0 35px;
            text-align: center;
        }

        .ant-modal-footer{
            padding: 0;
            border: none;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;

            .ant-btn{
                span{
                    margin: 0 auto;
                }
            }
        }
    }
}

.standard-request-modal{
    .ant-modal-body{
        padding: 70px 50px 30px;
        font-size: 16px;
    }

    .ant-modal-content{
        border-radius: 5px;
    }

    .ant-modal-footer{
        display: flex;
        justify-content: space-between;
        padding: 0 50px 40px;
        flex-direction: row-reverse;
        border-top: none;

        .ant-btn{
            margin: 0;

            &-default{
                justify-content: center;
            }
        }
    }

    .modal-action{
        display: flex;
        padding: 30px 0 10px;
        justify-content: center;
    }
}

.slick-arrow{
    background: rgba(var(--text-color-rgb), 0.3) !important;
    width: 50px !important;
    height: 50px !important;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    border: none;
    cursor: pointer;

    &:hover{
        background: rgba(var(--text-color-rgb), 0.6) !important;
    }

    .anticon{
        color: var(--base-color);
        font-size: 36px;
        position: relative;
        top:  1px;
    }

    &.slick-prev{

        .anticon{
            transform: rotate(90deg);
        }

    }

    &.slick-next{

        .anticon{
            transform: rotate(-90deg);
        }

    }

    &.slick-disabled{
        transform: scale(0);
    }
}

.color-grid{
    display: flex;
    margin-bottom: 40px;

    .color-group{
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li{
            width: 30px;
            height: 30px;

            &.current{
                .color-select-button{
                    font-size: 26px;
                }
            }
        }

        li + li{
            margin-top: 17px;
        }

        & + .color-group{
            margin-left: 12px;
        }

        .color-select-button{
            cursor: pointer;
            width: 100%;
            height: 100%;
            padding: 0;
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            font-size: 0;
            color: var(--base-color);

            @extend %transition;
        }
    }
}

.show-more{
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--sub-text-color);
    font-weight: bold;

    @extend %transition;

    &:hover{

        color: var(--primary-color-hex);
    }

}

.grid{
    &-five-col{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: minmax(200px, auto);
        grid-column-gap: 16px;
        grid-row-gap: 30px;
    }

    &-two-col{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(180px, auto);
        grid-column-gap: 20px;
        grid-row-gap: 30px;
    }
}


.ant-pagination{

    &-item-link:not([disabled]),
    &-item-active,
    &-item{
        &:focus,
        &:hover{
            border-color: rgba(var(--primary-color), 0.8) !important;

            color: rgba(var(--primary-color), 0.8) !important;

            a{
                color: inherit;
            }
        }
    }

    &-item-active{
        border-color: var(--primary-color-hex);

        a{
            color: var(--primary-color-hex);
        }
    }
}

.export-button{
    background-color: transparent;
    border: none;
    height: 40px;
    font-size: 13px;
    font-weight: bold;
    color: var(--primary-color-hex);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover{
        color: var(--text-color);

        .anticon{
            color: var(--text-color);
        }
    }

    .anticon{
        font-size: 38px;
        color: var(--primary-color-hex);
        transition: all 0.3s ease-in-out;

        &.anticon-loading{
            font-size: 18px;
            margin-right: 6px;
        }
    }
}